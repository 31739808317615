/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactHtmlParser from 'react-html-parser';

import { httpClient } from 'src/helpers';
import { Page, InputField, Button } from 'src/components';
import HeaderBanner from 'src/assets/images/Dekra_LP2_Hero.png';
import iPhoneLarge from 'src/assets/images/Dekra_LP2_iPhoneLarge.png';
import iPhoneSmall from 'src/assets/images/Dekra_LP2_iPhoneSmall.png';
import Screen from 'src/assets/images/Dekra_LP2_Screen.png';
import DekraLogo from 'src/assets/images/Dekra_LP2_Dekralogo.png';
import SCVerifiedLogo from 'src/assets/images/Vulcain_LP2_SCVerifiedlogo.png';
import Thumbnail from 'src/assets/images/Vulcain_LP2_Thumbnail.png';

import Check from 'src/assets/images/check.png';

const VideoURL =
  'https://storageambafrontdev.blob.core.windows.net/dev/media/video_Sharecare_ISO20121_son1.mp4';

const CONTACT_US_SCHEMA = Yup.object().shape({
  firstName: Yup.string().max(250, 'forms.too_long').required('required'),
  lastName: Yup.string().max(250, 'forms.too_long').required('required'),
  from: Yup.string().email('format').max(250, 'forms.too_long').required('required'),
  phoneNumber: Yup.string()
    .max(250, 'forms.too_long')
    .matches(
      /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[ ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,4})?$/,
      'format'
    ),
  companyName: Yup.string().max(250, 'forms.too_long').required('required'),
  jobTitle: Yup.string().max(250, 'forms.too_long'),
  message: Yup.string().max(250, 'forms.too_long').required('required'),
});

const INITIAL_CONTACT_VALUES = {
  firstName: '', // = first name
  lastName: '', // = last name
  from: '', // = email
  phoneNumber: '', // = phone number
  companyName: '', // = company name
  jobTitle: '', // = job title
  message: '', // = message
};

let listenerVideoPlayAdded = false;
let eventVideoPlaySent = false;
let listenerVideoEndAdded = false;

function addVideoPlayListener() {
  if (typeof document !== `undefined` && !listenerVideoPlayAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('play', () => {
        if (window.gtag && !eventVideoPlaySent) {
          window.gtag('event', 'Video_dekra_marketing_lp_play');
          eventVideoPlaySent = true;
        }
        listenerVideoPlayAdded = true;
      });
    }
  }
}

function addVideoEndedListener() {
  if (typeof document !== `undefined` && !listenerVideoEndAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('ended', () => {
        if (window.gtag) window.gtag('event', 'Video_dekra_marketing_lp_ended');
        listenerVideoEndAdded = true;
      });
    }
  }
}

function handleButtonFormLinkClick() {
  if (window.gtag) window.gtag('event', 'Button_formLink_dekra_marketing_lp_click');
}

function handleContactFormInputClick(name) {
  if (window.gtag) window.gtag('event', `Input_contactForm_${name}_dekra_marketing_lp_click`);
}

const HomeCommunication = () => {
  const { t } = useTranslation('vulcain');

  const [contactSuccess, setContactSuccess] = useState(null);

  const handleContact = async (values, { setSubmitting, resetForm }) => {
    if (window.gtag) window.gtag('event', 'Click_contact_us');
    setSubmitting(true);

    try {
      const { status } = await httpClient.post('/contact/landing?origin=dekra', values);

      if (status === 200) {
        setSubmitting(false);
        setContactSuccess('SUCCESS');
        resetForm();
      } else {
        throw Error('Contact request failed.');
      }
    } catch (error) {
      setContactSuccess('ERROR');
      setSubmitting(false);
    }
  };

  setTimeout(addVideoPlayListener, 100);
  setTimeout(addVideoEndedListener, 100);

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light text-primary-dark">
        <div className="container">
          <div className="flex flex-col-reverse items-start justify-between pb-12 lg:flex-row">
            <div className="pt-12 md:pt-20 md:ml-28">
              <div className="max-w-2xl">
                <h1 className="text-2xl font-bold tiempos">{t('dekra:lp2.header.tagline1')}</h1>
                <h1 className="pt-4 text-5xl tiempos">{t('dekra:lp2.header.tagline2')}</h1>
                <p className="flex flex-col gap-6 pt-10 text-primary-dark last:pb-0">
                  {t('dekra:lp2.header.introductions', { returnObjects: true }).map((item) => (
                    <div>{ReactHtmlParser(item)}</div>
                  ))}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end max-w-xl">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" />
            </div>
          </div>
        </div>
      </Page.Header>
      <Page.Main className="text-primary-grey">
        <div className="bg-white">
          <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="m-auto text-center">
              <span className="text-4xl text-primary-dark tiempos md:text-5xl">
                {t('dekra:lp2.organization_tagline')}
              </span>
            </div>

            <div className="grid grid-cols-1 mt-10 sm:mt-14 md:mt-24 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3">
              <div className="grid gap-10 group place-items-center">
                <div className="flex w-full place-content-center aspect-w-1 aspect-h-1 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                  <svg
                    width="159"
                    height="144"
                    viewBox="0 0 159 144"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M149.293 134.812V19.5452L57.5747 0V134.812H51.0474V34.2459L9.70737 44.6032V134.812H0V144H159V134.812H149.293ZM23.4316 59.638H37.8253V74.0046H23.4316V59.638ZM23.4316 84.529H37.8253V98.8956H23.4316V84.529ZM23.4316 109.253H37.8253V123.787H23.4316V109.253ZM121.175 34.58H135.568V48.9466H121.175V34.58ZM121.175 59.471H135.568V73.8376H121.175V59.471ZM121.175 84.3619H135.568V98.7285H121.175V84.3619ZM121.175 109.253H135.568V123.619H121.175V109.253ZM96.2368 34.58H110.631V48.9466H96.2368V34.58ZM96.2368 59.471H110.631V73.8376H96.2368V59.471ZM96.2368 84.3619H110.631V98.7285H96.2368V84.3619ZM71.2989 34.58H85.6926V48.9466H71.2989V34.58ZM71.2989 59.471H85.6926V73.8376H71.2989V59.471ZM71.2989 84.3619H85.6926V98.7285H71.2989V84.3619ZM71.2989 109.253H85.6926V123.619H71.2989V109.253ZM96.2368 109.253H110.631V134.812H96.2368V109.253Z"
                      fill="#E8F1EF"
                    />
                  </svg>
                </div>

                <div className="text-center">
                  <b>Débutant une démarche d’appropriation ISO 20121</b>
                  <br />
                  <br />
                  Votre enjeu : <br />
                  décrypter les exigences opérationnelles <br />
                  de la norme et lancer la démarche
                </div>
              </div>
              <div className="grid gap-10 group place-items-center">
                <div className="flex w-full place-content-center aspect-w-1 aspect-h-1 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                  <svg
                    width="159"
                    height="144"
                    viewBox="0 0 159 144"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M149.293 134.812V19.5452L57.5747 0V134.812H51.0474V34.2459L9.70737 44.6032V134.812H0V144H159V134.812H149.293ZM23.4316 59.638H37.8253V74.0046H23.4316V59.638ZM23.4316 84.529H37.8253V98.8956H23.4316V84.529ZM23.4316 109.253H37.8253V123.787H23.4316V109.253ZM121.175 34.58H135.568V48.9466H121.175V34.58ZM121.175 59.471H135.568V73.8376H121.175V59.471ZM121.175 84.3619H135.568V98.7285H121.175V84.3619ZM121.175 109.253H135.568V123.619H121.175V109.253ZM96.2368 34.58H110.631V48.9466H96.2368V34.58ZM96.2368 59.471H110.631V73.8376H96.2368V59.471ZM96.2368 84.3619H110.631V98.7285H96.2368V84.3619ZM71.2989 34.58H85.6926V48.9466H71.2989V34.58ZM71.2989 59.471H85.6926V73.8376H71.2989V59.471ZM71.2989 84.3619H85.6926V98.7285H71.2989V84.3619ZM71.2989 109.253H85.6926V123.619H71.2989V109.253ZM96.2368 109.253H110.631V134.812H96.2368V109.253Z"
                      fill="url(#paint0_linear_9667_20888)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_9667_20888"
                        x1="79.5"
                        y1="0"
                        x2="79.5"
                        y2="144"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#00BFA5" />
                        <stop offset="1" stopColor="#00BFA5" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                <div className="text-center">
                  <b>Préparant la certification ISO 20121</b>
                  <br />
                  <br />
                  Votre enjeu : <br />
                  déployer progressivement et vérifier votre système <br />
                  de management en vue d’un audit de certification
                </div>
              </div>
              <div className="grid gap-10 group place-items-center">
                <div className="flex w-full place-content-center aspect-w-1 aspect-h-1 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                  <svg
                    width="160"
                    height="144"
                    viewBox="0 0 160 144"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M149.793 134.812V19.5452L58.0747 0V134.812H51.5474V34.2459L10.2074 44.6032V134.812H0.5V144H159.5V134.812H149.793ZM23.9316 59.638H38.3253V74.0046H23.9316V59.638ZM23.9316 84.529H38.3253V98.8956H23.9316V84.529ZM23.9316 109.253H38.3253V123.787H23.9316V109.253ZM121.675 34.58H136.068V48.9466H121.675V34.58ZM121.675 59.471H136.068V73.8376H121.675V59.471ZM121.675 84.3619H136.068V98.7285H121.675V84.3619ZM121.675 109.253H136.068V123.619H121.675V109.253ZM96.7368 34.58H111.131V48.9466H96.7368V34.58ZM96.7368 59.471H111.131V73.8376H96.7368V59.471ZM96.7368 84.3619H111.131V98.7285H96.7368V84.3619ZM71.7989 34.58H86.1926V48.9466H71.7989V34.58ZM71.7989 59.471H86.1926V73.8376H71.7989V59.471ZM71.7989 84.3619H86.1926V98.7285H71.7989V84.3619ZM71.7989 109.253H86.1926V123.619H71.7989V109.253ZM96.7368 109.253H111.131V134.812H96.7368V109.253Z"
                      fill="#00BFA5"
                    />
                  </svg>
                </div>

                <div className="text-center">
                  <b>Certifiée ISO 20121</b>
                  <br />
                  <br />
                  Votre enjeu : <br />
                  faciliter la surveillance et le maintien <br />
                  de votre conformité
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container pt-16 pb-16 md:pb-48">
          <div className="flex justify-center">
            <video src={VideoURL} className="md:max-w-screen-md" controls poster={Thumbnail}>
              Sorry, your browser doesn&apos;t support embedded videos.
            </video>
          </div>
          <br />
          <br />
          <div className="flex items-center justify-center">
            <div
              onClick={handleButtonFormLinkClick}
              onKeyDown={handleButtonFormLinkClick}
              role="button"
              tabIndex={0}
            >
              <Button size="lg" round="true" as="link" to="#contact-form">
                {t('dekra:lp2.form_link')}
              </Button>
            </div>
          </div>
        </section>
        <section className="flex flex-col items-center px-6 pt-16 pb-16 md:px-12 md:flex-row bg-secondary-green-light vulcain_sectionheight">
          <div className="flex flex-col items-center space-y-8">
            <div className="flex flex-row items-center px-2 py-1 bg-white border-2 border-gray-300 rounded-full md:px-8 md:py-6">
              <div className="flex justify-center w-16 md:w-auto">
                <img src={SCVerifiedLogo} alt="Sharecare | HealthSecurity" className="h-full" />
              </div>
              <div className="pr-5 text-xs md:text-base">with</div>
              <div className="flex justify-center w-16 md:w-32">
                <img src={DekraLogo} alt="Sharecare | HealthSecurity" className="h-full" />
              </div>
            </div>
            <div className="space-y-4 md:w-7/12">
              {t('dekra:lp2.check_list', { returnObjects: true }).map((item) => (
                <div className="flex items-center space-x-4">
                  <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                  <span>{ReactHtmlParser(item)}</span>
                </div>
              ))}
            </div>
            <br />
            <br />
            <div className="flex items-center justify-center">
              <div
                onClick={handleButtonFormLinkClick}
                onKeyDown={handleButtonFormLinkClick}
                role="button"
                tabIndex={0}
              >
                <Button size="lg" round="true" as="link" to="#contact-form">
                  {t('dekra:lp2.form_link')}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex pt-8 md:pt-0 w-96">
            <img src={iPhoneLarge} alt="Sharecare | HealthSecurity" />
          </div>
        </section>
        <section className="container pb-20 pt-14 md:pt-36">
          <div className="m-auto text-center">
            <span className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('dekra:lp2.data_tagline')}
            </span>
          </div>
          <div className="flex justify-center space-y-12 md:pt-8 md:items-center md:flex-row md:space-x-12 md:space-y-0 ">
            <img
              className="hidden w-1/4 md:block md:h-full md:w-48"
              src={iPhoneSmall}
              alt="Sharecare | HealthSecurity"
            />
            <img className="md:w-1/2" src={Screen} alt="Sharecare | HealthSecurity" />
          </div>
        </section>
        <section id="contact-us" className="overflow-hidden">
          <div className="h-20 bg-secondary-green-light" />
          <div id="contact-form" className="bubble-gs">
            <Formik
              initialValues={INITIAL_CONTACT_VALUES}
              validationSchema={CONTACT_US_SCHEMA}
              onSubmit={handleContact}
            >
              {({ isValid, isSubmitting, dirty }) => (
                <Form className="container relative w-screen max-w-screen-lg pt-24 space-y-6 md:pt-48">
                  <div className="flex flex-col items-center justify-center text-center">
                    <span className="text-4xl lg:text-5xl text-primary-dark tiempos">
                      {t('lp2.contact.tagline')}
                    </span>
                  </div>
                  <div className="grid grid-cols-1 gap-6 pt-4 md:grid-cols-2">
                    <InputField
                      name="firstName"
                      label="lp2.contact.firstName"
                      autoComplete="given-name"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <InputField
                      name="lastName"
                      label="lp2.contact.lastName"
                      autoComplete="family-name"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <InputField
                      name="from"
                      label="lp2.contact.email"
                      autoComplete="email"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <InputField
                      name="phoneNumber"
                      label="lp2.contact.phone"
                      autoComplete="phone"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <InputField
                      name="companyName"
                      label="lp2.contact.company"
                      autoComplete="company"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <InputField
                      name="jobTitle"
                      label="lp2.contact.role"
                      namespaces={['vulcain']}
                      onClick={(e) => handleContactFormInputClick(e.target.name)}
                    />
                    <div className="col-span-1 md:col-span-2">
                      <InputField
                        type="textarea"
                        name="message"
                        label="lp2.contact.message"
                        namespaces={['vulcain']}
                        onClick={(e) => handleContactFormInputClick(e.target.name)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="w-full max-w-sm">
                      <Button
                        type="submit"
                        disabled={!(isValid && dirty) || isSubmitting}
                        loading={isSubmitting}
                      >
                        Envoyer
                      </Button>
                    </div>
                  </div>
                  {contactSuccess && (
                    <div>
                      {contactSuccess === 'SUCCESS' && (
                        <div className="px-6 py-4 text-gray-800 rounded-md bg-primary-light">
                          Votre message a bien été envoyé! Une personne de l&apos;équipe vous
                          contactera dans les 2 jours.
                        </div>
                      )}
                      {contactSuccess === 'ERROR' && (
                        <div className="px-6 py-4 text-white bg-red-500 rounded-md">
                          Oups, merci de ré-essayer plus tard.
                        </div>
                      )}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </Page.Main>
    </Page>
  );
};

export default HomeCommunication;
