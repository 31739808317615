import React from 'react';

import VERTICALS from 'src/constants/verticals.json';

import Ftg from 'src/views/static/ftg/pages';
import Venue from 'src/views/static/venue/pages';
import Vaccine from 'src/views/static/vaccine/pages';
import Hospitality from 'src/views/static/hospitality/pages';
import Cars from 'src/views/static/cars/pages';
import Schools from 'src/views/static/schools/pages';
import Cruises from 'src/views/static/cruises/pages';
import Gifen from 'src/views/static/gifen/pages';
import Vulcain from 'src/views/static/vulcain/pages';
import Dekra from 'src/views/static/dekra/pages';
import Greenshield from 'src/views/static/greenshield/pages';
import Equilibre from 'src/views/static/equilibre/pages';

const Index = () => {
  switch (process.env.GATSBY_VERTICAL) {
    case VERTICALS.FTG:
      return <Ftg />;
    case VERTICALS.VENUE:
      return <Venue />;
    case VERTICALS.VACCINE:
      return <Vaccine />;
    case VERTICALS.HOSPITALITY:
      return <Hospitality />;
    case VERTICALS.CARS:
      return <Cars />;
    case VERTICALS.SCHOOLS:
      return <Schools />;
    case VERTICALS.CRUISES:
      return <Cruises />;
    case VERTICALS.GIFEN:
        return <Gifen />;
    case VERTICALS.VULCAIN:
      return <Vulcain />;
    case VERTICALS.DEKRA:
      return <Dekra />;
    case VERTICALS.GREENSHIELD:
      return <Greenshield />;
    case VERTICALS.EQUILIBRE:
      return <Equilibre />;
    default:
      return <Ftg />;
  }
};

export default Index;
