/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Page } from 'src/components';

import badgeSharecare from 'src/assets/images/badge_unverified.png';
import EQPasteur from 'src/assets/images/equilibre_pasteur_logo.png';
import EQIT from 'src/assets/images/equilibre_iphone_tablet-cut.png';
import logo from 'src/assets/images/Equilibre_LP_EquilibreLogo.png';

const HomeCommunication = () => (
  <Page>
    <Page.Header className="pt-16 bg-secondary-green-light text-primary-dark">
      <div className="container">
        <div style={{
          backgroundColor: '#f3f7f9',
          fontFamily: 'Open Sans, sans-serif'
          }}>
          <div id="home" style={{
              width: '100%',
              minHeight: '100vh',
              paddingTop: '82px'
          }}>
              <div className='grid grid-cols-1 xl:grid-cols-2'>
                  <div className='p-8 md:pl-12 xl:pl-20 md:p-28 xl:pr-0 2xl:pr-28 mt-8 xl:mt-0'>
                      <div className='pb-14 xl:pb-10' style={{
                          fontSize: '36px',
                          fontWeight: 700,
                          lineHeight: '46px',
                          color: '#353A60'
                      }}>
                          <span style={{color: '#00bfa5'}} >Renforcez</span> le capital <span style={{color: '#00bfa5'}} >bien-être</span> et santé de tous vos collaborateurs
                          <span>
                              <span className='mr-2'>
                                  &nbsp;avec le Programme
                              </span>
                              <img src={logo} alt="Logo Equilibre" className='inline-block' style={{
                                  height: '31px',
                                  marginTop: '-10px'
                              }}/>
                          </span>
                      </div>
                      <div style={{
                          border: '1px solid #E5ECEE',
                          marginTop: '50px'
                      }} />
                      <div className='block md:flex justify-between items-center' style={{
                          padding: '40px'
                      }}>
                          <div style={{
                                  fontSize:'16px',
                                  lineHeight: '24px',
                                  color: '#353A60'
                              }}>
                              <span>Créé par <span style={{fontWeight: 700}}>Sharecare</span></span><br/>
                              <span>et le <span style={{fontWeight: 700}}>Centre Prévention Santé Longévité</span></span><br/>
                              <span style={{fontWeight: 700}}>de l&apos;Institut Pasteur de Lille</span>
                          </div>
                          <a className='inline-block' href="https://www.sharecare.fr" target="_blank" rel="noreferrer">
                              <img src={badgeSharecare} alt='Equilibre Sharecare Logo' className='object-contain hidden md:block mx-8' style={{
                                  width: '80px'
                              }} />
                          </a>
                          <a className='inline-block' href="https://pasteur-lille.fr/centre-prevention-sante-longevite" target="_blank" rel="noreferrer">
                              <img src={EQPasteur} alt='Equilibre Pasteur Logo' className='object-contain hidden md:block' style={{
                                  width: '80px'
                              }} />
                          </a>
                          <div className='flex mt-5 md:mt-0 block md:hidden'>
                              <a className='inline-block' href="https://www.sharecare.fr" target="_blank" rel="noreferrer">
                                  <img src={badgeSharecare} alt='Equilibre Sharecare Logo' className='object-contain mr-8' style={{
                                      width: '80px'
                                  }} />
                              </a>
                              <a className='inline-block' href="https://pasteur-lille.fr/centre-prevention-sante-longevite" target="_blank" rel="noreferrer">
                                  <img src={EQPasteur} alt='Equilibre Pasteur Logo' className='object-contain' style={{
                                      width: '80px'
                                  }} />
                              </a>
                          </div>
                      </div>
                      <div style={{
                          border: '1px solid #E5ECEE'
                      }} />
                  </div>
                  <div className='relative h-auto flex items-center justify-end'>
                      <img src={EQIT} alt="" />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </Page.Header>
    <Page.Main className="text-primary-grey" />
  </Page>
);

export default HomeCommunication;
