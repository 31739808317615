/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import { Page } from 'src/components';
import GifenLogo from 'src/assets/images/gifen-logo-bleu-grand.png';
import HeaderBanner from 'src/assets/images/Vulcain_LP2_Hero.png';

const HomeCommunication = () => {
  const { t } = useTranslation('gifen');

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light text-primary-dark">
        <div className="container">
          <div className="flex flex-col-reverse items-start justify-between pb-12 lg:flex-row">
            <div className="pt-12 lg:ml-28">
              <div className="max-w-2xl">
                <img src={GifenLogo} alt="Gifen" />
                <h1 className="text-5xl tiempos">{t('lp2.header.tagline1')}</h1>
                <p className="flex flex-col gap-6 pt-6 text-primary-dark last:pb-0">
                  {t('lp2.header.introductions', { returnObjects: true }).map((item) => {
                    const { type, content, sublist } = item;
                    switch (type) {
                      case 'list':
                        return (
                          <div>
                            {ReactHtmlParser(content)}
                            <ul className="list-disc pl-7">
                              {sublist.map(({ subcontent }) => (
                                <li>{ReactHtmlParser(subcontent)}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      default:
                        return <div>{ReactHtmlParser(content)}</div>;
                    }
                  })}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end max-w-xl">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" />
            </div>
          </div>
        </div>
      </Page.Header>
    </Page>
  );
};

export default HomeCommunication;
